#breadcrumb{
    margin: 0 auto;


    .breadcrumb{
        padding: 0;
        background-color: transparent;
        font-weight: 400;
        margin: 0 auto;
        //border: 1px solid blue;
            // Séparateur '>' si ce n'est pas le 1er li qui lui correspond à accueil
        li:not(:first-child){   
            &::before {
                content: '>';
            }
        }    

        span{
            padding-bottom: .5em;
        }

        a:hover{
            color: $color1;
            span{
                border-bottom: 2px solid $color1;
            }
        }
        li{
           
            a{
                color: $color0;
            }
            &:hover a{
                color: $color1;
            }
        }
        
        // font différent pour le 1er lien (accueil)
        li:first-child{
            a{
                font-weight: 500;
            }
        }


        // ***** Valide (personnalise que le 1er lien)
        // li:first-child{
        //     a{
        //         color: $color0;
        //         font-weight: 500;

        //         &:hover{
        //         color: $color1;
        //         }
        //     }
        // }

        li.active{
            font-style: italic;
            font-weight: 200;
        }

    }
}