@mixin media-breakpoint-desktop() {

  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin media-breakpoint-mobile() {

  @include media-breakpoint-down(md) {
    @content;
  }
}

// Extends définition
.card-hover-effect{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card-title{
  text-align: center;
  font-weight: 400;
  font-size: 1.1rem;
  color: #C201A5;
}

.hometopertitle{
  position: absolute;
  bottom: 10px;
  left: 20px;
  max-width: 100%;
  background: #fff;
  padding: 0.5em;
  opacity: .7;
  .text-bloc-title,.text-bloc-title2{display: block;}
  .text-bloc-title{
    font-size: 1.5em;
    line-height: initial;
    font-weight: 800;
  }

  .text-bloc-title2{
    font-style: italic;
    font-size: .8em;
  }
}

// Checkout headers
.checkout-header{
  text-transform: initial;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}


// titre panier (ex: total)
.cart-titles{
    font-family: 'Quicksand', sans-serif;
    //color: #c201a5;
    font-weight: 600;
    padding-bottom: .5rem;
    border-bottom: 1px solid #333333;
    background-color: transparent;
}
// @mixin media-breakpoint-desktop() {

//   @include media-breakpoint-up(lg) {
//     @content;
//   }
// }
// @mixin media-breakpoint-mobile() {

//   @include media-breakpoint-down(md) {
//     @content;
//   }
// }