.user-info{     
    #header &{      // .user-info inclu dans le div #header
        // #account-icon{
        //     width: 35px;
        //     height: 35px;
        // }

        a.u-link-body span{
            display: none;
        }

        a.u-link-body span.customer-name{
            color: $color1;
            display: inline-block;
            vertical-align: -webkit-baseline-middle;
        }

    }
}