

#header_top_message{
  display: flex;
  flex-direction: column;
  background-color: $top-message-bg;
  color: $white;
  text-align: center;
  .warning-info{background-color: red;}
  .shippinginfo{
    background-color: $color2;
    em{display: none;}
  }
}
@include media-breakpoint-down(sm) {
  #header_top_message{
    font-size: .9rem;
    // .shippinginfo{
    //   em{display: block;font-size: .8rem;}
    // }
  }
}
// .l-header{
//   background-color: $header-bg;
//   margin-bottom: $spacer;
//   box-shadow: $header-box-shadow;
// }

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  // padding-top: $spacer*1.25;
  // padding-bottom: $spacer*1.25;
  // border: 1px solid yellow;

      figure{
        margin: 0;
        // border: 1px solid blue;
      }
}

  // .header__logo{
  //   vertical-align: middle;
  //   border: 1px solid red;
  // }

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}


      @include media-breakpoint-mobile{
        #header{
          padding: 1em 0.2em 0 0;   // pour décaler panier et compte du bord droit
        }

        .header__logo{   // Si abs, le bloc n'apparait car il n'a pas de largeur
          width: 45px;
          height: 45px;
        }
          // .header__logo,
          // .header__logo--checkout{
          //   max-width: 50%;
          // }
          ic-m{
            padding: 0;
            margin: 0;
          }
            #ic{display: none;}
          
          .header__search {
            width: 100%;
            padding: 0;
            flex: auto;
            order: 10;
            margin-top: 10px;
          }
          .header__logo--checkout{
            margin: 0 auto;
          }
      }


      @include media-breakpoint-desktop(){
        #header-without-menu{
          padding: 1em 2em;
          
        }

        .header-top__col{
          width: 30%;
        }
            // logo
            #ic-m{
              display: none;
              //border: 1px solid black;
            }
      }


      @include media-breakpoint-up(xl) {
        .header-top__col{
          width: 25%;
        }
      }


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}

// ============== Icons & SVG =================
/* LOGO */
#svgs{display: none;}   // masque le bloc de définition des svg

#logo-figcaption{     // Masque le text caption du logo
  position: absolute;
  top: 0;
  font-size: .5em;
  line-height: .8em;
   opacity: 0;
}

a#main-logo{
      use.ic-1, use.ic-m{
        fill: $logo-color1;
        color: $white;
      }
        .ic-2{
        color: $logo-color1;
        fill: $color0;
      }
      // Survol du logo
      &:hover{
        use.ic-1, use.ic-m{fill: $color0;}
        use.ic-2{color: $color0;}
      }
}

/* ====== icons */
svg.icon{
  width: 24px;
  height: 24px;
  //   width: 35px;
  // height: 35px;
  color: $color1;

    &:hover{
      color: $color0;
    }

}


// svg.ic-assur{
//   width: 50px;
//   height: 50px;
// }

// text des icones (ex : mon compte "Client N")
// .i-txt{
//   font-size: .6em;
// }

//@media (min-width:320px)
//@include media-breakpoint-desktop
@include media-breakpoint-up(sm){
  #wrapper{
    padding: 0 3em;
  }
}

#hometoper{
  // border-top: 1px solid black;
  // border-bottom: 1px solid black;
  width: 100%;
  // margin: 1em auto;
  // background-color: $color17;
  background-image: linear-gradient(to bottom,$color17, rgba(247, 248, 250, 0));
  

  img{
    border-radius:10px;
 // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }


  #hometoper-container{
    //background-color: lightgray;
    display: flex;
   // max-width: 90%;
    justify-content: center;
    margin: auto;
    padding: 0.5em;


    img{
      &:hover{
      @extend .card-hover-effect;
      ~ .text-bloc{transform: rotate(-5deg); opacity: .9;}
      }
      // &:hover ~ .text-bloc{transform: rotate(-5deg);}
    }


    .toperbloc{
       display: flex;
        justify-content: center;
       width: 100%;
    .text-bloc{
      @extend .hometopertitle;
      // position: absolute;
      // bottom: 10px;
      // left: 20px;
      // max-width: 100%;
      // background: #fff;
      // padding: 0.5em;
      // opacity: .7;

      //   span{
      //     display: block;
      //   }

      //   .text-bloc-title{
      //     font-size: 1.5em;
      //     line-height: initial;
      //     font-weight: 800;
      //   }

      //   .text-bloc-title2{
      //     font-style: italic;
      //     font-size: .8em;
      //   }
    }
}

    .left{
      position: relative;
      // width: 90%;
      padding: 0.3em;
      img{
        width: 98%;
        // &:hover{@extend .card-hover-effect;}
      }
    }

    .right{
     // border: 2px solid yellow;
     display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .pic{
        position: relative;
        width: 50%;
        margin: auto;
            padding: 0.3em;
       // padding: 0.3em;
       // border: 1px solid blue;
      }
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }

  .wtoperbloc-in{
    position: relative;
    .text-bloc{
      @extend .hometopertitle;
    }
  }

}

.wtoperbloc-in{
  width: 50%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // border:1px solid red;
}


@media (max-width: $breakpoint-sm){
  #hometoper{
    font-size: .9em;
      #hometoper-container{
        display: block;

        .toperbloc{
            .text-bloc{
              .text-bloc-title{
                font-size: 1.1em;
                font-weight: 600;
                }
            }

        }
        .left{
          .text-bloc{bottom: 5%; left: 5%;}
        }

        .right{
          .text-bloc{bottom: 10%; left: 5%;}
        }

     }
  }
}
@include media-breakpoint-up(sm){
   #hometoper{font-size: .7em;}
}
@include media-breakpoint-up(sm){
  #hometoper{font-size: .8em;}
}
@include media-breakpoint-up(xl){
  #hometoper{
    #hometoper-container{max-width: 70%;}
  }
}






// bloc accueil
#home-blocs{
  display: none;
  padding: 1rem;
  //max-height: 500px;
  
  background-color: yellow;
  border: 1px solid red;

    #col1, #col2{
      display: flex;
    }

    // col-12 sur mobile et col-sm-6
    #col1{    
      background-color: lightblue;
      max-height: 500px;

      // align item centre l'image qui apparait moins étirée
      // ajout d'un overflow pour masquer ce qui dépasse du bloc
      align-items: center; // centre l'image dans le conteneur
      overflow: hidden;
      justify-content: end; // place le bloc à droite pour diminuer l'écart avec la colonne de droite
      padding: 0;

      // .card a{
      //   max-height: 100%;
      //   img{    
      //     max-height: 100%;
      //   }
      // }

    }

    // on applique display flex au conteneur
    // col-12 sur mobile et col-sm-6
    #col2{
      flex-wrap: wrap;
      background-color: lightpink;

      .row{
        min-height: 50%;
        max-height: 250px;
        overflow: hidden;
        border: 2px solid yellow;
      }

      #row-top{
        background-color: black;
        align-items: flex-start;
      }

      #row-bottom{
        background-color: red;
        img{
          border:1px solid red;
          width: 100%;
        }
      }
        .landscape-bloc{
          height: auto;
          width: 100%;
          background-color: transparent;
        // max-height: 185px;
          border: 1px solid green;
        }


    }
    
        .small-bloc{
          max-width: 50%;
        }

      .card{
        border: none;
      }
}


// bloc homme femme enfants
#family
{
  //border: 1px solid blue;
  margin-top: .5em; // pour écraser le margin top de 3em sur .home-bloc
  .row{
    //justify-content: space-between;
   // align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row!important;

      .family-card{
        width: 20%;
        max-width: 200px;
        color: $color-txt;
        margin: 0 2%;
        
        &:hover{
         // @include card-hover-effect;
         @extend .card-hover-effect;
          color: $color1;
          border-color: $color1;
        }
      }

      figure{
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 500;
        // color: $color-txt;
        text-align: center;
        margin: auto;
        padding: .5rem 0;


      }

      // @media (max-width: 320px){
      //   #family.row{flex-direction: row!important;}
      // }

  }
  
  @include media-breakpoint-down(sm){
    .row{
      .card{width: 45%;  margin: .5rem 0;}

      .family-card{
        width: 45%;
        max-width: 200px;
        color: $color-txt;
        margin: 0 2%;
      }

    }
  } 

  @media (max-width: 320px)
    {

        .row
          {
            display: flex;
            flex-direction: column;
            // background-color: yellow;

            .card{
              width: 100%;
            }

          }

      
    }

  
}

// @include media-breakpoint-up(xs){
//   #family{
//     background-color: red;
//   }
// }


// bloc licences du moment
section#licences,
#reassurance{
  .card:hover{
    // transform: scale(1.05);
    // box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    @extend .card-hover-effect; 
  }
}


section#licences{
  //background-color: blue;
  .card{
    min-height: 150px;
    max-height: 200px;
    max-width: 250px;
    margin: 2rem auto;

    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
   // padding: 14px 80px 18px 36px;
    cursor: pointer;

    figure{
      margin: auto;
      text-align: center;
        img{
          display: block;
          height: auto;
          //margin: auto;
          //max-width: 50%;
        }
        figcaption{
          opacity: 0;
          position: absolute;
          border: 1px solid black;
          display: flex;
          width: 100%;
          // display: block;
          bottom: 0;
          left: 0;
          justify-content: center;
        }
    } 
  }
  // .card:hover{
  //   transform: scale(1.05);
  //   box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  // }
}

// Réassurance
#reassurance{
  // border: 1px solid blue;
  //background: linear-gradient(#FF20DA, #610D55)
  // background: linear-gradient(180deg, rgba(255,255,255,1) 36%, rgba(194,1,165,1) 100%);
    section{
      // border:1px solid yellow;
      .row{justify-content: center;}
      
      .card{
       // max-width: 20%;
       //width: 100%;
        text-align: center;
        margin: .2rem auto;
        border-radius: 10px;
        background:rgba(255,255,255,.7);

        //border-color: $color1;
        //background: transparent;
        .card-header, .card-body{color: $color0;}
        :hover{
          .card-header, .card-body{color: $color1;}
        }

        .card-header{
          font-weight: bold;
          height: 50px;
          padding: .75rem 0;
          background: transparent;
          border: none;
        }
        svg{
          //max-width: 80px;
          // width: 24px;
          // height: 24px;
          //width: 50%;
          width: 30%;
          max-height: 50px;
          margin: auto;
          vertical-align: middle;
          // height: 50px;

              use
              {
                color: $color1;
              }

        }

        .card-body{
          padding: 1rem 0;
          .card-text{
            line-height: 1.1rem;
            font-size: .9rem;
          }
        }

      }

    }
    

    @include media-breakpoint-up(md){
     // padding: 2rem auto;
      border-top: 2px solid $color1;
      border-bottom: 2px solid $color1;
      .container{
        padding: 2rem 0;
      }
      // border-color: $color1;
    }

}


