#email-stock-notification{
    border: 0.1em solid $color19;
    width: 100%;
    font-family: 'Quicksand';

    h5{
        font-family: 'Quicksand';
        font-size: 1.2em;
        background-color: $color19;
        color: white;
        padding: 0.5em;
        text-align: center;
    }

    div.js-mailalert{
        text-align: center;
        padding: 1em;

        input.form-control{
            margin-bottom: 0.7em;
        }
        input.form-control:focus{
            border-color: $color15;
            box-shadow: none;
        }
    }

    button.js-mailalert-add{
        background-color: $color19;
        border: none;
        padding: 0.6em;
        border-radius: 0.3em;
        font-weight: 700;

        &:hover{
            background-color: $color9;
        }
    }    

}
    