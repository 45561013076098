.page-header--my-account,
#addresses .page-header,
#order-detail .page-header,
.page-customer-account .page-header{
  padding: 0;
  background-color: transparent;

  h1{
    @extend .title-bloc;
  }
}


.account-links{
  @extend .align-items-stretch;
  > a{
    margin-bottom: $spacer;
    color: $color18;
  }
  .link-item{
    @extend .card;
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    i{
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;

    }
  }
}

.address{
  @extend .card;
  height: 100%;
}
.address-header{
  @extend .card-header;
  background-color: transparent;
}
.address-body{
  @extend .card-body;
}
.address-footer{
  @extend .card-footer;
  display: flex;
  justify-content: space-between;

}

.form--100{
  min-width: 100%;
  width: 100%;
}


// compte client
.page-customer-account,
#addresses,
#order-detail{
  .page-wrapper{
    border: none;
  }

  .page-footer{
    border-color: $color1;
    background-color: transparent;
  }
}

