.products{
  @extend .row;
  align-items:stretch;
}

// Product Miniature
.product-miniature{
  @extend .col-6;
  padding: 0 .4em;
  // font-family: 'Roboto', sans-serif;
  font-family: 'Quicksand', sans-serif;
  font-size: .8rem;
  // border: 1px solid red;
  .card-body{
    padding: 1em 0;

    h2.product-title, p.product-title{    //p.product-title (pour toucher le bloc dans module featuredproduct)
      font-size: .9rem;
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      //border: 1px solid red;
    }
  }

  ul#flags-under-product{
    flex-direction: column-reverse;
    .new{
      font-weight: 600;
      width: auto;
      margin: 0 auto;
    }
  }

  #flag-undisplayed{
    // height: 1em;
    // line-height: 1em;
    // min-height: 1em;
    // width: 1px;
    box-shadow: none;
    
    // padding: 0;
    background-color: white;
    // border: 1px solid blue;
  }
  .product-flags{
    // position:absolute;
    width: 100%;
    // top:0;
    bottom: 0;
    display: flex;  
     margin-bottom: 0;
     z-index: 1;
     align-items: flex-start;
    //  background-color: lightgray;

      .product-flag{

       padding: 0 $spacer/2;
        color: #fff;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
        
        &.new{
          background-color: $color2;
          color: $color13;
          box-shadow: none;
          //background-color: $white;
         // color: $color0;
        }
      
        &.discount-product, &.discount-amount{
    //       justify-content: center;
    // align-items: center;
          // position: absolute;
          // right: 0;
          // background: $color-product-discount;
        }
      }
      
    }


}

// @media (max-width: 180px){

// }

// inférieur à 320 js-product-list
// @media (max-width: 320px) {
//   .products{
//     // max-width: 100%;

//     .product-miniature{
//       @include make-col(6);
//       padding: 0 .3em;
//       border: 1px solid green;
//     }

//   }
// }

// 768 px +
@include media-breakpoint-up(md){
  .product-miniature{
    @include make-col(4);
    // border: 1px solid red;
  }


}

// 992 px +
@include media-breakpoint-desktop {
  .product-miniature{
    margin-top: 0 !important;
    // original
    // @include make-col(4); 
    @include make-col(3); // fabrique des blocs de 3 cols donc 3 x 4 = 12 colonnes donc 4 produits affichés au lieu de 3 sur desktop
    margin: 2.5em 0;
    // border: 1px solid green;
  }

  .layout-full-width .product-miniature{
    // @include make-col(3);
    @include make-col(3);
  }

  section#product-full-desc-mobile{
    display: none!important;
  }
}

// 1200 px (6 produits par ligne)
@include media-breakpoint-up(xl){
  .layout-full-width .product-miniature, .product-miniature{

    @include make-col(2);
    
   
  }
}



.slick-slide .product-miniature{
  max-width: 100%;
}
/* Product miniature*/
.card-product.card{
  height:100%;
  position: relative;
  text-align: center;
  //background-color: $pm-bg-color;
  //border-width: $pm-border-width;
  //border-color: $pm-border-color;
 //border: none;
  &:hover
  {
    .highlighted-informations{
      transform:translateY(0);
    }
    @extend .card-hover-effect;

  }

  .discount{
    // display:none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img{
    width: 100%;
  }

}

// :hover bloc affiché au survol
.highlighted-informations{
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;
  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}

.quick-view{
  cursor:pointer;
}

// .product-flags{
//   position:absolute;
//  // top:0;

//   margin-bottom: 0;
//   z-index: 1;
//   //left:-$spacer/4;
//   display: flex;
//  // flex-direction: column;
//   align-items: flex-start;
// }
// .page-content--product .product-flags{
//   left:0;
// }
// .product-flag{
//   top:0;
//   margin-top: $spacer/2;
//   padding: $spacer/4;
//   color: #fff;
//   background: theme-color('primary');
//   text-transform: uppercase;
//   font-weight: 600;
//   box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);

//   &.discount-product{
//     background: $tertiary;
//   }
// }
.product-flags{
  padding: 0.2em 0.5em!important;
    // li.new{
    //   background-color: $color2;
    //   color: $color13;
    // }
    li.on-sale{
      position: absolute;
      top: 0;
      left: -10px;
      padding: 0.2em 0.5em!important;
      transform: rotate(-10deg);
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5)!important;
      background-color: $color4;
      color: $color5;
    }
  
}

.color{
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-title{

  & a {
    color: $color0;
    &:hover{
      color: $color1;
      border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
    }

  }
  .card-product:hover & {
    color: $pm-name-hover-color;
    & a {
      color: $pm-name-hover-color;
    }
  }
}

/* PRODUCT PAGE */

#product main{
  .product-flags{
    margin-bottom: 0;
    // border: 1px solid red;
    .product-flag{
      width: auto;
    }
    .new{
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.3em 1em;
      transform: rotate(10deg);
      text-align: center;
      background-color: $color2;
    //  border: 1px solid red;
      color: $color13;
      font-weight: 600;
      //left: 0;
      // width: auto;
          // margin: 0 auto;
    }
    }

    .slick-prev, .slick-next{
      padding: 0;
    }

    h1{
      text-transform: capitalize;
      /* border: 1px solid red; */
      font-family: Quicksand;
      font-weight: bold;
      margin-bottom: 1.5rem;

      .title-info{
        display: block;
        font-size: .7em;
        font-style: italic;
        font-weight: 500;
        /* font-variant: all-small-caps; */
        text-transform: initial;
      }
    }

    h2.products-section-title{
      text-align: left;
      font-family: 'Quicksand', sans-serif;
      text-transform: inherit;
      padding-bottom: .5rem;
      border-bottom: 1px solid black;
      // background-color: lightyellow;
        span{display: block;}

        .main-title{
          font-size: .9rem;
          font-weight: 700;
          padding-left: 0;
        }

        .second-title{
          font-size: .8rem;
          font-style: italic;
        }
      }   

      section.featured-products{
        h2.products-section-title{
          border-color: $color1;
          .main-title{color: $color1;}
        }
        .products{
          justify-content: normal;
          article.product-miniature{
           min-width: 10%;
           justify-content: space-between;
           //max-width: 20%;
           // background-color: lightgreen;
         }

        }
      } 

.product-info-title{
  color: $color19;
  font-size: .9rem;
  padding-right: .3rem;
}

    // .product-top-info{
    // //  background-color: lightpink;
    //   font-size: 1rem;
    // //  margin-top: 2rem;
    //     .feature-name{
    //       color: $color19;
    //     }
    // }

    .product-information{
      
      #product_description_short{
        font-style: italic;
        font-size: .8rem;
        // margin: 2rem 0;
      }
      // utilisé pour masquer le texte (https://css-tricks.com/line-clampin/)
      .line-clamp {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
      }
      #voir-plus{
        // float: right;
        // margin: -1em 1em 0;
        margin: 0;
        padding: 0;
        font-size: .9em;
        font-style: italic;
        text-decoration: underline;
      }
    }

    .product-prices{
      padding: 1rem 0 2rem;
    }

    .product-variants{
      display: flex;
      margin: 1.5rem 0;
      align-items: center;
      flex-wrap: wrap;
    }
    // #notsized{  // affiche "en stock" à la ligne si on ce n'est pas une déclinaison de tailles
    //   display: block;
    // }

    .product-variants-item{     
      // margin: 1rem 0;
      // align-items: center;
      display: flex;
      align-items: center;
      margin-bottom: 0!important;
      //margin: 1rem 0 2rem 0;

        label{
          margin: 0;
          text-transform: capitalize;
          font-weight: 700;
          padding-right: 1rem;
          // border: 1px solid red;
        }

        // select pour la taille
        select.custom-select{
        
          option.product-available{color: $color-validate;}
          option.product-unavailable{color: $color4;}

        }

    }
.current-price-display {font-size: 1.8rem;}
    div.add{
      // border: 1px solid red;
      display: block;
      width: 100%;
      // margin: 1em;
     // background-color: lightgray;
      
     
      button{
       // background-color: none!important;
        // padding: 1em;
        span{
          font-family: 'Quicksand', sans-serif;
          font-size: 1em;
          font-weight: 500;
          }
          
      
      }

      #btn-add{
          background-color: #c201a5;
          &:hover{
            background-color: #db07bc;
          }
      }
      #btn-pre-order{
          background-color: $color9!important;
          &:hover{
            background-color: $color15;
          }
      }
      #btn-available-soon{
        opacity: 1; // pour contrer le disabled
        width: 100%;
        background-color: #00aaea;
        color: white;
       // text-transform: capitalize;
        font-weight: 600;
        // border: 1px solid;
        // &:disabled{
        //   opacity: 100;
        // }
      }
      #btn-available-soon, #btn-pre-order{
        text-transform: capitalize;
      }
     
     

      .btn-add-to-cart{
        //@extend .d-flex;
        font-size: 1.2rem;
        line-height: 1.2rem; 
        text-transform: uppercase;
        font-weight: 500;
        padding: 1rem 0;
        //white-space: nowrap;

       // background-color: $color3!important;
        // background-color: $color3;
        &:hover{
          background-color: $main-color-hover;
        }
      }
    
      .btn-add-to-cart-txt{
        white-space: normal;
        // display: block;
        //border: 1px solid blue;       
      }
    }


    // accordion description / Composition & entretien
    .accordion{
      margin: 1.5rem 0;
      .accordion_title{
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        padding: 1rem 0;
        border-bottom: 1px solid black;
        // border-bottom: 1px solid $color1;
        // background-color: gray;
        // border: 1px solid red;
      }
    }

  #product-details{font-size: .9rem;}
  
  .product-caract, .data-sheet{
    display: flex;
    margin: .5rem;
    // background-color: blue;
    .value{
      padding-left: 1em;
    }
  }
  .product-content{
    display: block;
    margin-bottom: 1.5em;}


    .product-description{
      // border-top: 1px solid red;
    }
    #product-details{
      // border: 1px solid blue;
      .col{
        flex-basis: auto;
      }
    }
    

  .product-accessories{
 
      padding: 0 15px;
    
      margin-top: 1.5rem;
      // h2{
      //   text-align: left;
      //   font-family: 'Quicksand', sans-serif;
      //   text-transform: inherit;
      //   padding-bottom: .5rem;
      //   border-bottom: 1px solid black;
      //     span{display: block;}

      //     .main-title{
      //       font-size: .9rem;
      //       font-weight: 700;
      //       padding-left: 0;
      //     }

      //     .second-title{
      //       font-size: .8rem;
      //       font-style: italic;
      //     }
      //   }   
  
        .products{
          display: block;

          article.product-miniature{
           // background-color: yellow;
            max-width: 100%;
            padding-right: 0;
            padding-left: 0;
          } 
          
         .card{
          flex-direction: row;
          align-items: center;
          // border: 1px solid gray;
            .col{
              width: 50%;
            }
           .card-picture{
            flex-basis: auto; // permet d'avoir des images plus grande
            padding: 0;
           }
           .card-body{
             //border: 1px solid blue;
             .product-title{
                font-size: .9rem;
                font-weight: 500;
                font-family: 'Roboto', sans-serif;
             }
             .price{
              font-size: 1rem;
             }
             .product-description{
              padding-left: .5rem;
             }
           }

         } 
        }
      
    }

    #accessories-desktop{
      // background-color: lightgrey;
      width: 100%;
      article.product-miniature{
        min-width: 18%;
      }
    }
}



/* Product Page : Modal  */
#product{
  #blockcart-modal{
    font-family: 'Quicksand';
    color: $color0;
    // background-color: blue;

    .modal-header{
      border-bottom: 1px solid $color0;
        .modal-title{
          font-size: .9rem;
         // color: $color0;
        }
    }
    .modal-body{
      margin-bottom: 0;
      
      .product-name{
        color: $color0;
        font-size: .9rem;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 1rem;
      }
      
      // strong{
      //   color: $color-twitter;
      // }

      .product-price{
        color: $color1;
        font-size: 1.1rem;
        font-weight: 700;
      }

      .cart-content{display: none;}
    }
  }
}



/* PRODUCT PAGE MEDIA QUERIES */
@media (max-width: 320px){
  
  // #test{
  //   .col{
  //     flex-basis: auto;
  //     background-color: yellow;
  //   }
  // }

  #product main{
      // background-color: lightgreen;
      font-size: .8rem!important;
      
      h1{
       font-size: .9rem;
      }

      .product-top-info{margin-top: 0;}

      .product-prices{
        padding: 0;
        
        //margin: 1rem 0;
        margin-bottom: 1rem;
        .product__product-price{flex-direction: column-reverse;}
      }

    div.add{
        font-size: .9rem;  
        
          .add-to-cart{
              line-height: 1rem; 
             // border: 1px solid yellow;
            }
        }

      // .product-accessories{
      //   padding: 0 15px;
      // }

  }
}

// de 321 px à la taille SM (576 px)
@media (min-width: 321px) and (max-width: 575px){
  #product main{
    // background-color: lightgray;

    h2.products-section-title{
      .main-title{
        font-size: 1.1rem;
      }

      .second-title{
        font-size: 1rem;
      }
    }
  }
}

// + 576 px
@include media-breakpoint-up(sm){
 

  
  #product main{

    h2.products-section-title{
      // background-color: lightblue;
      .main-title{font-size: 1.2rem;}
      .second-title{font-size: .9rem;}
    }

    .product-variants{
      display: flex;
      align-items: center;
      // margin: 1rem 0;
    }

    #product-availability{
      margin-left: .8rem;
      // background-color: lightblue;
    }
  
      .products{
        //display: block;
        display: flex!important;
        width: 100%;
        justify-content: space-around;
        // border: 1px solid red;
        margin: 0;

          article.product-miniature{
            //opacity: .3;
           // margin-right: .5rem;
            // border: 1px solid grey;
            min-width: 45%;
            max-width: 45%!important;
          }
      }
    }

  }


@include media-breakpoint-down(lg){
  #product main{

    .images-container{
      .position-relative{width: 100%;}
      .product-thumbs{
        display: none!important;
        }
    }
   
    .products{
      .product-accessories{
          article.product-miniature{
            //opacity: .3;
            margin-right: .5rem;
            // border: 1px solid grey;
            min-width: 50%;
           // max-width: 50%;
           max-width: 20%;
            background-color: red;
            
          }
      }
    }


  }
}


@media (min-width: 992px){
  #product main{
    .product-thumbs{
          button.slick-prev, button.slick-next{
            display: none!important;
          }
    }


  }


}
@include media-breakpoint-up(lg){
  #product main{
    //background-color: lightpink;
    h1{
      font-size: 1.6rem;
     }
     .product-top-info, .product-info-title {
      font-size: 1rem;
     }
  

    //  .product-flags{
    //   .new{ left: 150px;} // la colonne product-thumbs (max-width: 130px) 
    //   }


    //  .images-container{
    //   border: 1px solid blue;
    //  }

     // surcharge du slick.scss
    .product-thumbs{
          display: block;
          max-width: 130px;
          max-height: 50px;
          // border: 1px solid red;
          
          // buttons
          button.slick-prev, button.slick-next{
            // display: none;
            position: relative;
            left: 30px;
          }

          button.slick-prev{
            // left: 50px;
            transform: rotate(90deg);
            // border: 1px solid green;
          }

          button.slick-next{
            transform: rotate(90deg);
            // border: 1px solid black;
          }

          .slick-list{
              max-height: 700px;
            .slick-track{
                display: flex;
              flex-direction: column;
              // background-color: yellow;
              }
              .slick-cloned{
                display: none;
              }

          }
     }

    
      #accessories-desktop{
        // background-color: red;
          article.product-miniature{
            //opacity: .3;
            margin-right: .5rem;
            // border: 1px solid grey;
            min-width: 30%;
            max-width: 30%;
            
          }
      }

      .product-full-desc{
        display: flex;

        .description{
          width: 70%;
        }
        .product-tab-info{
          width: 30%;
        }

        .desc-title{
          // background: lightgray;
          width: 50%;
          text-align: center;
          margin: 1rem auto;
          border-bottom: 3px solid $color18;
          padding-bottom: .8rem;
          font-size: 1.1rem;
          color: $color18;
      
        }
        #product-details, #product-description{
          padding: 1em;
        }
        // background-color: blue;
        #product-details{
          display: block;   // supprime le collapse
          border-left: 1px solid gray;
          // padding: 1em;

          // background-color: red;
        }
      }
     
  }

}




.products-imagescover{
  box-shadow:$card-box-shadow;;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc{
  display: block;
}

.product-thumbs{
  width:$product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin:0 auto;
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

}
.product-thumb{
  margin: $product-thumb-margin;
  //padding:5px;
  //height:  auto;
  width:$product-thumb-width;
  height:$product-thumb-height;
  border:2px solid transparent;
  &.slick-current {
    border: 2px solid theme-color('primary');
  }
}


.btn-zoom{

  position: absolute;
  //right: 40%;
  left: 48%;
  bottom: 48%;
  padding: 0;
  
  // right: $spacer;
// bottom: $spacer;
  cursor: pointer;
  display: none;
 
  line-height: 1;
  background-color: $white;
  width: 3rem;
  border-radius: 50%;
  & .material-icons{
  font-size: 3*$font-size-base;
  }
}
.images-container:hover .btn-zoom{
  display: block;
}
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices

.product__product-price{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.product__product-price.has-discount{
  
  .current-price{
    display: inline-block;
  }
}
.price{
  
  color:$product-price-current-color;
  font-size: 1.4rem;
  //font-size:$product-price-current-font-size;
  font-weight:$product-price-current-font-weight;
  font-family: $product-price-current-font-family;
 // padding-right: 1em;
  &.current-price-discount{
    color:$product-price-discount-color;
    font-size: 1.4rem;
  //  font-size:$product-price-discount-font-size;
    font-weight:$product-price-discount-font-weight;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price{
  color:$product-price-regular-color;
  //font-size:$product-price-regular-font-size;
  font-weight:$product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount{
  text-transform: uppercase;
  color: #FFF;
  //background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: .25*$spacer;
}
// Flags
.discount-percentage,
.discount-amount{
  background-color: $color-product-discount;
  width: auto;
    margin: 0 auto;
    
  // justify-content: center;
  //   align-items: center;
}

//product add to cart
p.product-minimal-quantity{
  
  margin: 0;
}



//product tabs
.product-tabs{
  .nav-tabs{
    font-size: $font-size-base * .9;
  }
  //@extend .card;
  //@extend .card-block
  //box-shadow:$card-box-shadow;;
}
.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
    margin-bottom: 0;
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection{
  justify-content: space-between;
  align-items: center; 
  // border: 1px solid red;
}

.product-count{
  display: flex;
    /* justify-content: flex-start; */
    align-content: baseline;
}



.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-bottom: $spacer * 2.5;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 1em; // correction bug prix card pas visible completement
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}
#product-availability{
    font-size: .9rem;
   
  .product-available,.product-unavailable{
      // font-weight: bold;
    
      font-weight: 500;
  }
  .product-available{
    
    color: $color-validate;
  }
  .product-unavailable, .product-last-items{
    color: $color4;
  }
}


.size select{
  //border: 1px solid red;

  option.product-available{
    

    // display: inline-block;
    // width: auto;
    // content: "En stock";
   // background-color: yellow;

    &::after{
      //display: inline-block;
      //content: ">>";
      // width: 10px;
    }
  } 
}

.product-add-to-cart{
  // border: 1px solid blue;
  #more_info{
    // background-color: lightcoral;
    font-size: .9rem;
   // color: $color1;
    margin-top: 0.5rem;
    padding: .5em;
   
  }

  button.add-to-cart{
    // width: 100%;
    border: none;
    svg{
      color: $white;
      vertical-align: baseline;
    }
    span{
     font-size:  $font-size-base;
    }
  }

  .product-quantity{
  margin-top: -($spacer);
  }
.qty{
  margin: 0 $spacer;
  margin-top: $spacer;

}
.add{
  margin-top: $spacer;

}
}
.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}
.sort-by-row{
  display: flex;
  // width: 100%;
  justify-content: flex-end;
  // align-items: center;
}
// bouton Filtrer sur mobile
#search_filter_toggler{
  border: none;
  background-color: $color1;
  padding: .3rem 1rem;
  margin-right: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
  &:hover{
    background-color: $color0;
  }
}
.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs{
  justify-content: center;
}

