@import "variables/layout";
@import "variables/product-miniature";
@import "variables/searchbar";

$breakpoint-sm: 576px;

// font
$font2: 'Quicksand', serif;

/* Définition des couleurs du thèmes */
$logo-color1:#C201A5;   // couleur du logo (pour changer de couleur pour halloween)
$color-txt: #6b6a6af0;
$color0: #333333;
$color1: #C201A5;  
$color2: #F7B234;
$color3: #aa0191;
$main-color-hover: #ff01d9;
$color4: red;
$color5: #fff;
$color6: #87ceeb;
$color7: #CC0099;
$color9: #000; 
$color10: #3a0031;
$color11: #d0d1d3;
$color12: #F9F9F9;
$color13: #F4F4F4;
$color14: #8d0d52;
$color15: #00AAEB;
$color16: #FAFAFA;
$color17: #f7f8fa;
$color18: #555555;
$color19: #8B8B81;
$color20: #212529;
$color21: #8b8b8126;
$color22: #e8e9eb;
$color23: #e9e6df;  // fond oups sorciere
$color24: #495057;  // couleur des titres des filtres (desktop)
// $color22: #ebedef;
$color-validate: #1a8917;
$disabled: #e5e5e5;

$color-product-discount: red;
$color-twitter:#00ACEE;
$color-facebook: #3b5998;

$color-new-product-flag: #F7B234;


$top-message-bg: #000033;
$top-message-color: #fdfdfd;


// Icons






//BS touchspin
$bs-touchspin-boxshadow:        2px 2px 4px 0 rgba(0,0,0,.2);

//cart
$promo-code-bg:                 darken($body-bg,10%);

//offcanvas
$zindex-offcanvas :             $zindex-modal;
$offcanvas-width :              300px;

//product slider
$product-thumb-margin:          5px;
$product-thumb-width:           100px;
$product-thumb-height:          100px;
$product-to-show:               3;//if you edit this, you have to add centerPadding ppy in data-slick with same value
$product-thumb-wrap-width:      $product-to-show * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-width-2:    2 * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-height:     $product-thumb-height + $product-thumb-margin * 2;

//prices
$product-price-current-color:             $color1;
$product-price-current-font-size:         $font-size-base;
$product-price-current-font-weight:       700;
$product-price-current-font-family:       null;

$product-price-regular-color:             $color0;
//$product-price-regular-font-size:         $font-size-base * .875;
$product-price-regular-font-size:         $font-size-base * .900;
$product-price-regular-font-weight:       400;
$product-price-regular-font-family:       null;


$product-price-discount-color:         $color1;
$product-price-discount-font-size:         null;
$product-price-discount-font-weight:       700;
$product-price-discount-font-family:       null;




//product color box
$colorbox-border-color:         #232323;

//product add to cart spinner
$spinner-width-addtocart:$btn-font-size-lg;
$spinner-height-addtocart:$btn-font-size-lg;
$spinner-borderwidth-addtocart:2px;


//social share
$social-share-size:             1.5 * $font-size-base;
$social-share-bg-color:         $gray-300;

//main menu
$menu-topitem-font-size:            $font-size-base;
$menu-topitem-font-weight:            600;
$menu-topitem-color:            $gray-800;
$menu-topitem-text-transform:   uppercase;
$menu-topitem-padding:          $spacer/2;
$menu-topitem-margin-left:          $spacer*2;

$menu-sub-top: 60px;

  //main menu mobile
$menu-item-padding:             $spacer/2;
$menu-item-border-color:        $border-color;
$menu-sub-mobile-bg-color:      $wrapper-bg;

//heading
$h1-text-transform: uppercase;

//footer
$footer-margin-top:             $spacer;
$footer-padding-top:             $spacer;

//util
//$card-box-shadow : $box-shadow !default;
$card-box-shadow : null !default;

//product section

$section-title-font-family: null;
$section-title-color: null;
$section-title-size: null;
$section-title-font-weight: null;
$section-title-text-align: center;
$section-title-text-transform: uppercase;
$section-title-margin-bottom: $spacer;

//blockcart
$blockcart-color: null;
$blockcart-background-color: null;
$blockcart-inactive-color: null;
$blockcart-inactive-background-color: null;
$blockcart-padding:null;


//block left column
$block-border-color: null;





