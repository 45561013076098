.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

#footer{
 // background-color: aliceblue;
  margin-top: 0;
  //border-top: 1px solid black;
  padding-top: 1.2rem;
  //background: linear-gradient(#fff, #b3b2bf, #444449);
  //background: linear-gradient(#fff, #b3b2bf, #000);
  //background: linear-gradient(#fff, #b3b2bf, #fff);

    p.footer__title--desktop, a.footer__title--mobile{
       // color: $color1;
       font-weight: 500;
      }
    a.footer__title--mobile{border: none;}
    p.footer__title--desktop{
      font-size: 1.1rem;

    }

    ul li{
        a{
          font-weight: 400;
          color: #000;
          padding: .3rem 0;
        }
        a:hover{
          color: $color1;
          text-decoration: underline;
        }
    }

}

#after-footer-link{
  background-color: black;
  justify-content: center;
  color: $color5;
  font-size: .8rem;
  padding: .5rem 0;
  margin: 0;
  a{
    color: $color5;
  }
  span{
    margin:0 1rem;
  }
}