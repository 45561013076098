.shopping-cart{
    position: relative;

    // shopping cart uniquement quand il est placé dans le #header
    #header &{  

        // #cart-icon{
        //         width: 35px;
        //         height: 35px;
        // }
        #cart-icon{
            position: relative;
        }
        .blockcart__label{display: none;}

        span.blockcart__count{
            // nb produit dans le panier
            position:absolute;
            background-color: $color1;
            color: $white;
            padding: 1px;
            border-radius: 70%;
            height: 1.2em;
            width: 1.2em;
            right: -5px;
            text-align: center;
            line-height: 1.2em;
        }
        // hover du nombre de produit dans le panier
        a.u-link-body:hover{
            span.blockcart__count{
                background-color: $color0;
            }
        }
    }
}