
#category{

        .category-title{
            @extend .card-title;
            color: $color20;
            text-transform: capitalize;
            text-align: left;
            margin-top: 1em;
            margin-bottom: 1em;
        }
        h2.category-title-2{
            font-size: .8rem;
            font-style: italic;
            margin-top: 1em;
            margin-bottom: 1em;
        }
    
}

#cat-themes{
    //background-color: blue;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    .cat-bloc{
        width: 45%;
        height: auto;
        margin: 0.5em auto;
        // margin: 0.2em;
        border: 1px solid $color19;
  
        
        &:hover{
            @extend .card-hover-effect;
            .figure-caption{
                //background-color: $color10;
            }
        }
        .figure{margin-bottom: 0;}
        .figure-img{margin-bottom: 0;}
        
        .figure-caption{
            font-family: $font2;
            color: $color20;
            font-size: .8rem;
            font-weight: 700;
            padding: .5em 0;
            text-align: center;
            border-top: 1px solid $color19;
            // background-color: $color7;
        }

    }
}

@include media-breakpoint-up(sm){
    #cat-themes{
        // background-color: blueviolet;
        .cat-bloc{
            width: 24%;
        }
    }
  }

  @include media-breakpoint-up(md){
    #cat-themes{
        // background-color: yellow;
        .cat-bloc{
            width: 19%;
        }
    }
  }

  @include media-breakpoint-up(lg){
    #cat-themes{
        // background-color: pink;
        .cat-bloc{
            width: 15%;
            .figure-caption{font-size: 1em;}
        }
    }
  }

#halloween{
    h1{
        @extend .card-title;
        text-transform: capitalize;
    }
    #banner-container{
        background-color: black;
    }
    #banner{
        display: flex;
        max-height: 200px;
        width: 100%;
    }
}

//   @include media-breakpoint-up(xl){
//     #cat-themes{
//         background-color: gray;
//         .cat-bloc{
        
//             width: 9%;
//         }
//     }
//   }