.menu{
  padding-bottom: $spacer;

}
.menu-top{
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
}
.menu__item-link--top{
  color: $menu-topitem-color;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding: $spacer/2;
}

// vince
@include media-breakpoint-up(sm){
  #_desktop_top_menu{   // on cible #_desktop_top_menu pour appliquer le style du dekstop uniquement (classe non visible sur mobile)
    background-color: $color17;
    border-bottom: 1px solid #e8e9eb;
      .menu-top{
        max-height: 50px;   // contre le h-100 qui est défini sur menu-top et qui sert peut être sur mobile
        // border: none;
        // border-width: 1px 0;
        // border-style: solid;
        // border-color: $color11;
  
        
      }
      // sous menu full widht   
      .menu-sub__list--1 {
        flex-wrap: wrap;
        //justify-content: space-between;
        // flex-direction: row;
        li{
          width: auto;
          max-width: 200px;
         
         // min-width: 30%;
          a{
            max-width: 200px;
            .menu-images-container{
              position: relative;
              width: auto;
              //min-height: 180px;
              border: 1px solid $color19;
              
            }

            .menu-images-container{
              .title-container{
                font-family: 'Quicksand',serif;
                text-align: center;
             //   background-color: #fff;
                font-weight: 600;
                /* display: block; */
              //  position: absolute;
                /* width: 100%; */
               // left: 0;
               // bottom: 10px;
               // border: 1px solid green;
               // width: 100%;
              }
            }
          }
        }
      }

      .menu__item--current{
        .menu__item-header{
          a{
            color: $color1;
          }
        }

      }
      // li.menu__item--active{
      //   background-color: $color1;
      //   a{
      //     color: $color5;
      //   }
      // }
  }
}

@include media-breakpoint-up(lg){
    #_desktop_top_menu{
      .menu-sub__content{
        width: 100%;
       // background-color: #fff;
        //border: 1px solid black;

        .menu-sub__list--1 {
        //  width: 80%;
          margin: 0 auto;
          background-color: red;
  
        }

      }
    }
}



#_mobile_top_menu{
  ul.h-100{
    height: auto!important; // Mobile only : règle le bug de gros écart entre les entrées ex: "deguisements" et "deguisements enfants"
  }
}

// original
// @include media-breakpoint-desktop {
//     #menu{
//       padding-left: 0;
//       padding-right: 0;

//       nav.menu{
//               // background-color: teal;
//               padding-bottom: 0;
              
      
//               .menu-top{
//                 margin-top: 1em;
//                 border-bottom: 0!important;
//               }
//               .menu__item-header {
//                 height: 100%;
//               }
//               .menu-sub {
//                 position: absolute;
//                 opacity: 0;
//                 width: 100%;
//                 left: 0;
//                 top: 100%;
//                 z-index: -1;
//                 transition: all .5s ease;
//               }
//               .menu-sub__content > ul {
//                 padding: 10px;
//                 background-color: #fff;
//                 box-shadow: $box-shadow;
//               }
//               .menu__item--active .menu-sub {
//                 z-index: 2;
//                 opacity: 1;
//               }
//               .menu-sub__list--1 {  // deroulant principal
//                 // position: relative;
//                 display: flex;
//                 // width: fit-content;
//                 border: 1px solid red;
//               }
//               .menu__item--1 {
//                 padding-right: 10px;
//                 padding-left: 10px;
      
//               }
//               .menu__item-link--1 {
//                 text-transform: uppercase;
//                 color: $black;
//                 border: 1px solid yellow;
//                 .menu__item-link {
//                   color: $body-color;
//                 }
//               }
//             }
//     }
// }

// vincent
@include media-breakpoint-desktop {
  #menu{
    padding-left: 0;
    padding-right: 0;

    // nav.menu{
      #_desktop_top_menu{
            // background-color: teal;
            padding-bottom: 0;
            
    
            .menu-top{
              margin-top: 1em;
              border-bottom: 0!important;
            }
            .menu__item-header {
              height: 100%;
            }
            .menu-sub {
              position: absolute;
              opacity: 0;
              width: 100%;
              left: 0;
              top: 100%;
              z-index: -1;
              transition: all .5s ease;
            }
            .menu-sub__content > ul {     // grand déroulant
              padding: 10px;
              background-color: #fff;
              box-shadow: $box-shadow;
            }

            // .menu-sub{

            //   ul.menu-sub__list--1{
            //     // display: flex;
                
            //     li.menu__item--1 {
            //       display: flex;
            //       width: auto;
            //       border: 1px solid red;
            //     }
            //     li.menu__item--1 a.w-100{
            //         width: 30%!important;
            //         text-align: center;
            //         border: 1px solid green;
            //       }
                
            //     .menu-images-container{
            //       position: relative;
            //       border: 1px solid black;
            //     }
              
            //   }
            // }


            .menu__item--active .menu-sub {
              z-index: 2;
              opacity: 1;
            }
            .menu-sub__list--1 {  // deroulant principal
              // position: relative;
              display: flex;
              // width: fit-content;
              //border: 1px solid red;
            }
            .menu__item--1 {
              padding-right: 10px;
              padding-left: 10px;
              margin: 0.5em;
            }
            .menu__item-link--1 {
              text-transform: uppercase;
              color: $black;
              //margin: 1em;
              max-width: 200px;  // (taille de l'image max)
              // border: 1px solid yellow;
              .menu__item-link {
                color: $body-color;
              }
              &:hover{
                //@extend .card-hover-effect;
                // background-color: red;
                transform: scale(1.05);
                box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
              }
            }
          }
  }
}



//mobile
@include media-breakpoint-mobile() {
  .menu-top{
    flex-direction: column;
    margin-bottom: $spacer;
  }
  .menu-sub{
    width: 100%;
    transition: all .3s ease-out;
  }
  //remove padding from container class
  .menu-sub__content{
    padding: 0;
  }
  .menu__item-link--top{
    padding: 0;
  }
  .top-menu-mobile{
    margin-right: -$modal-inner-padding;
    margin-left: -$modal-inner-padding;
  }
  .menu__item{
    //padding: $spacer/2;
    border-bottom: 1px solid $menu-item-border-color;
    border-top: 1px solid $menu-item-border-color;
  }
  .menu__item--top:not(:first-child){
    border-top:0;
  }
  .menu__item--sub:last-child,
  .menu__item--sub:first-child:not(:last-child){
    border-bottom: 0;
  }
  .menu__item-header{
    display: flex;
    justify-content: space-between;
    padding: $menu-item-padding;
  }
  .menu__item-link--nosubmenu{
    padding: $menu-item-padding;
    display: block;
  }
  .menu-sub.show{
    background: $menu-sub-mobile-bg-color;
  }

  //increase offset
  .menu__item--1 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 10px;
    }
  }
  .menu__item--2 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 20px;
    }
  }
  .menu__item--3 {
    .menu__item-link--nosubmenu,
    .menu__item-header{
      margin-left: 30px;
    }
  }
}

#mobile_top_menu_wrapper{
  &.modal{
    .modal-header{
      border-bottom:0;
    }
    .modal-body{
      padding-top:0;
    }
  }
}

.menu__collapseicon{
  width: 3rem;
  height: 1.5rem;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}
