//color
$color1: #C201A5;  
$bg-dark :                      #dededd;
$tertiary:                      #f39d72;

//option
$enable-fluid-layout:            true;     // active ou non le mode full-width (container-fluid) cd _commons.scss sur le wrapper


/* e */
$header-bg:                     $white;
$header-box-shadow:             0 2px 6px 0 rgba(0,0,0,.2);
$header-nav-bg-color:           null;
$header-nav-color:              $body-color;
$header-nav-padding-y:          null;
$header-nav-border-color:       $border-color;
$header-top-border-color:       $header-nav-border-color;
/* MAIN */
$spacer-y : $spacer*2;
$wrapper-bg:                    #f1f1f1;



// Conteneu 
#content-wrapper{
    // padding-left: .7em;
    // padding-right: .7em;
    margin: 0 auto;
   width: 100%;
    //border: 1px solid red;

}
// inférieur à 320
// @media (min-width: 320px) {
//     #content-wrapper{
//         padding-left: 2em;
//         padding-right: 2em;
//         // opacity: .3;
//         // border: 1px solid green;
//     }
// }

// permet d'ajuster les produits de la page category sur mobile sous 380 px (problème d'affichage des lignes de 2 produits qui dépassent par rapport à la banière)
@media (max-width: 380px){
    #category{
        #wrapper{
            #content-wrapper{
                overflow: auto;
                padding: 1em;
                //background-color: yellow;
            }
        }
    }

}


/* Category */
// h1#category-title{
//     // font-family: 'Quicksand', sans-serif;
//     border-bottom: 2px solid black;
//     text-align: center;
//     padding-bottom: 0.5em;
//     font-weight: bold;
// }

/* FOOTER */
$footer-bg:                     $white;
$footer-container-margin-top:   $spacer;

/* Back to top */
#back-to-top{
    span{
        display: none;
    }
}


/* Modal Search Filter */
#offcanvas_search_filter{
    .modal-header{
        border-bottom: 1px solid $color1;
        h4{
            text-align: center;
            width: 100%;
            color: $color1;
        }
    }
}

/* Search results */
#search-results{
   margin-top: 1em;
    span:first-of-type{
        // background-color: red;
        font-style: italic;
    }
    .nb-results{
        font-size: .7em;
    }
}