#facetedsearch-container{
        padding: 0;
        margin: 0;

        #_desktop_search_filters_wrapper{
            text-align: center;
            
           // border-top: .1rem solid;
          //  border-bottom: .1rem solid;
         //  border-color: $color11;

            #search_filters{
               //background-color: $color16;
                h6{
                    padding-top: 1em;
                    font-size: 1em;
                    font-family: 'Roboto';
                    letter-spacing: .05em;
                    color: $color15;
                }
                .facet__block{
                    // margin: .7rem;
                    padding: 15px 15px 0;
                    // background-color: red;
                        .custom-select{
                            font-size: 1rem;
                            font-weight: 500;
                            border-radius: 4px;
                        }
                }

                .slider{
                    align-items: center;
                    // background-color: lightgray;
                    .slider-facet__title{
                        font-weight: 500;
                        margin-right: .8rem;
                        color: $color24;
                    }
                    p{
                        font-size: .9rem;
                        margin-bottom: .4rem;
                        // border: 1px solid red;
                    }
                }

            }
        }
        
        .clear-all-wrapper{
            width: 50%;
            margin:auto;
            background-color: $color14;
            button{
                color: white;
            }
        }


}

@media (min-width:1600px){
    #facetedsearch-container{
    //    border: 1px solid red;
       #search_filters{
            h6{
                font-size: 1.3em!important;
                // background-color: #00AAEB;
                // color: #fff!important;
            }
         }
    }
}