#product{

    @include media-breakpoint-desktop {
        
        #content-wrapper{
            padding-top: 1.5em; // écarte la fiche produit du fil d'ariane sur desktop
            // background-color: red;
        }
    }

}