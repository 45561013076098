@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";
@import "pages/_checkout";


@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";

/* Fichier spécifiques */
@import "modules/modules.scss";
@import "modules/ps_featuredproducts/ps_featuredproducts.scss";     // chargement de l'overide scss pour le module featuredproducts
@import "modules/ps_facetedsearch/ps_facetedsearch.scss";
@import "modules/ps_sharebuttons/ps_sharebuttons.scss";
@import "components/breadcrumb.scss";

// wide wide screen
.wwscreenonly{
  display: none!important;
}

// idealement 1500px
@media (min-width: 1000px){
  .wwscreenonly{display: block!important;}
}

@include media-breakpoint-mobile{
        // si on est pas sur la page d'accueil 
        // on écarte le wrapper qui est sous le menu (mobile uniquement)
    body[id]:not(#index) { 
        #wrapper{
            margin-top: 1em;
        }
    }
}


// partial/Mode de livraison.tpl 
#shipping-infos{
  h3{@extend .cart-titles }

  ul{
    li{
      margin: 0.9rem;
    }
  }
  .transporteur{
    margin-left: 1rem;
    margin-bottom: 0;
  }

  .type{
    font-weight: 600;
  }
.name{
  color: $color18;
}
  .delai{
    color: $color20;
    font-weight: 400;
    font-style: italic;
  }
  .prix{
    color: $color1;
    font-weight: 400;
  }
}

@include media-breakpoint-up(sm){
  #shipping-infos{
      
    ul{
      display: flex;
      justify-content: space-around;
      // border: 1px solid red;
      li{
        width: 30%;
        padding: 1rem;
        border: 1px solid black;

        p{
          margin: 0;
            .delai{
              display: block;
              // background-color: lightcoral;
            }
        }
      }
    }
  }
}

  // ul{
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;

  //   li{
  //     width: 40%;
  //     padding: 1rem;
  //     margin-bottom: 1rem;
  //     border: 1px solid black;
  //   }
  // }

  // .name, .type, .prix{
  //   display: block;
  // }


#pagination{
    display: flex;
  justify-content: center;
  .page-list{
    display: flex;
    gap: 10px; /* C'est l'écart entre les <li> */
  }
}

/* Media query pour les écrans encore plus petits (largeur inférieure à 300 px) */
@media (max-width: 300px) {
    #pagination{
        padding: 0;
        
        .page-list {
          flex-wrap: wrap;
          justify-content: center;
        }
        .page-item {
            gap: .4rem;
           // flex: 0 0 calc(33.33% - 20px); /* Chaque <li> prendra environ 33.33% de l'espace disponible, moins l'écart de 20px */
           // margin: 5px; /* Ajoute une marge pour séparer les éléments */
           .page-link{
            padding: .3rem;
           }
        }
    }
  }

  // Prix liste produits dans catégorie et search
  #category .price, #search .price{
    //background-color: blue;
    font-size: 1.2em;
  }



#reassurance{
  //  background-color: yellow;
  .home-bloc{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card{
      // background-color: blue;
      padding: 1em;
      //min-width: 40%;
      width: 40%;
      //min-height: 200px;
      margin-bottom: 20px; // Espacement vertical entre les cartes 
    }
  }
}

@media (max-width: 350px) {
  #category .price, #search .price{
      //background-color: blue;
      font-size: 1.1em;
    }

    #reassurance{
      // background-color: red;
      .home-bloc{
        .card{
          width: 80%;
          a{min-height:100px;}
          .card-header, .card-body{
            font-size: 1em;
          }
        }
      }
    }
}
// @include media-breakpoint-down(sm){
//   #reassurance{
//     background-color: green;
//    .home-bloc{
//      .card{
//        width: 35%; 
//      }
//    }
//  }
// }
@include media-breakpoint-up(sm){
  #reassurance{
  //  background-color: blue;
   .home-bloc{
     .card{
       width: 35%; 
     }
   }
 }
}

@include media-breakpoint-up(lg){
  #reassurance{
    // background-color: gray;
   .home-bloc{
     .card{
       width: 20%; 
     }
   }
 }
}

@include media-breakpoint-up(xl){
  #reassurance{
    // background-color: purple;
   .home-bloc{
     .card{
       width: 15%; 
     }
   }
 }
}


// 404 (Halloween et Noel)
#pagenotfound{
  #content-wrapper{margin-bottom: 3em;}
  #wrapper, #content{background-color: $color23;}
   #notfound{
      display: flex;
      flex-direction: column; // mobile
      align-items: center;
      
      // border: 1px solid black;
      
      p{margin-bottom: 2em;}
      
      img{
        width: 80%;
        height: auto;
        max-width: 300px;
       // max-width: 300px;
      }

      .oups{
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 2em;
      }

      .prompt{
        display: block;
        font-style: italic;
      }

      .back-to-home{margin: 2em; text-align: center;}

      .btn{
        display: block;
        width: fit-content;
        margin: 0 auto;
      }
      .btn-halloween{
        background-color: #000;
        border: black;
          &:hover{background-color: $color1;}
      }
   }

   .page-not-found-noel{
    background-color: $color5!important;
    
      #notfound{
        background-color: $color5!important;
      }

      p.back-to-home{
        margin:0!important;
        text-align: left!important;
      }
   }

}

@include media-breakpoint-up(md){
      #pagenotfound{
        #notfound{
         // width: 60%;
         margin: auto;
          flex-direction: row;
          div{padding: 2em;}
        }
      }
}

@include media-breakpoint-up(xl){
  #pagenotfound{
    #notfound{
      width: 60%;
    }
  }
}


