


#checkout {
    font-size: 'Roboto',sans-serif;
    // #checkout-personal-information-step{display: none;}    // Masquer partie 1 (informations personnelles)
    .loggedcustomer{display: none;} // templates\checkout\_partials\steps\personal-information.tpl
    #heading-checkout-personal-information-step{display: none;}
    // ajustement logo
    #main-logo{
      margin: auto;
    }

    .step-title{
        @extend .checkout-header;
    }

    .section.complete{
        // Bloc titres étape terminé aligné à gauche sur mobile
       .js-checkout-step-header{padding-left: 0;}    
    }

    #wrapper{
        .checkout-row{margin: 0; justify-content: center;}
        //.order-checkout{width: 70%;}
        
        #checkout-personal-information-step,
        #checkout-addresses-step,
        .order-checkout{
            // border: none!important; // supprimer la bordure du bloc
            width: 100%;
            margin: 0 auto;
        }
        #checkout-personal-information-step{ 
            border: none!important; // supprimer la bordure du bloc

        }
        // section.checkout-step{
        //     border: none!important; // supprimer la bordure du bloc
        //     width: 100%;
        //     margin: 0 auto;
        //     // background-color: red;
        // }
        .card-title{
            text-align: center;
            font-weight: 400;
            font-size: 1.1rem;
            color: #C201A5;
        } 
        #authentication-card{
       
            .card{
                margin-bottom: 3em;
                border: 1px solid $color19;
    
                .card-header{
                    @extend .checkout-header;
                    // font-size: 1.1rem;
                    // font-weight: 600;
                }
                .card-title{
                    text-align: center;
                    font-weight: 400;
                    font-size: 1.1rem;
                    color: #C201A5;
                }
                
                // .form-footer{
                //     .btn{
                //         width: 100%;
                //     }
                // }
    
                .customer-account-info, .custom-control-label{
                    font-style: italic;
                }
                .customer-account-info{margin-top: 1rem;}
            }
        }

        .form-footer{
            .btn{
                width: 100%;
            }
        }
        .continue{width: 100%;}
        

        #checkout-addresses-step, #js-checkout-summary{
            
               // margin-bottom: 3em;
                border: solid!important;
                border-width: 1px!important;
            border-color: $color19;

                    // .selected{
                    // border: 1px solid $color19;
                    // background-color: #C201A5;
                    // }
        }
        
        #content-checkout-addresses-step{
            #delivery-address{
                div.alias, div.company,div.vat_number{
                    display: none;
                }
            }
        }
            .accordion .selected .card{
                border: 2px solid $color19;
    
            }

            #delivery-addresses, #invoice-addresses{
                margin-bottom: 2rem;
                .card{
                &:hover{border: 2px solid $color19;}
                }
            }
        


    }

    // .checkout-footer{
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    // }
}  


@include media-breakpoint-up(md){
    #checkout {
       // background-color: blue!important;
            #js-checkout-process{
                width: 95%;
                // background-color: yellow;
            }

            //.order-checkout{width: 70%; background-color: lightcyan;} // le resume ne peut passer sur le coté
                #authentication-card{
                    // width: 60%;
                    .card{
                        max-width: 500px!important;
                         margin: 0 auto;
                    }
                }

                #checkout-addresses-step{
                        width: 80%;
                        #delivery-address{
                            width: 70%;
                            max-width: 500px;
                            margin: 0 auto;
                           // background-color: blue;
                        }
                        // #delivery-addresses{background-color: blue;}
                }
            // #checkout-addresses-step{
            //     width: 80%;
            //     max-width: 600px;
            //         // max-width: 600px!important;
            //         // margin: 0 auto;
            //         // background-color: bisque;
            // }

            #checkout-delivery-step, #checkout-payment-step{
                width: 100%;
            }

    }
}



@include media-breakpoint-up(lg){
        #checkout{
            #wrapper{padding: 0;
               // .checkout-row{display: block;}

                section#checkout-addresses-step{
                   // width: 70%;
                    //background-color: gray;
                    #delivery-address{
                        width: 70%;
                        max-width: 500px;
                        margin: 0 auto;
                        // background-color: green;
                    }
                    #delivery-addresses{
                        // background-color: yellow;
                    }
                }

                .order-checkout{
                    width: 20%; // on réduit le résumé pour qu'il vienne sur le côté
                }
            }
            #js-checkout-process, #order-checkout{
                width: 75%;
                // background-color: lightpink;
            }
            #checkout-addresses-step{
                width: 90%;
            }


            .checkout-process{
                display: flex;
                width: 100%;
                // width: 80%;
                flex-wrap: wrap;
               // background-color: red;

                #checkout-personal-information-step{
                    .content{padding: 0;}
                    width: 100%;
                    #authentication-card{
                        display: flex;
                        justify-content: space-around;
                        

                        .card{
                            height: fit-content;      
                        }
                            #login-card{
                                width: 40%;
                                max-width: 400px;
                            }
                            #register-card{
                                width: 50%;
                                max-width: 500px;
                            }
                        .customer-account-info, .custom-control-label{
                            font-size: .8rem;
                            // font-style: italic;
                        }

                        // .customer-account-info{margin-top:0;}
                        
                    }                  
                }



            }


        }
        .custom-control-label{font-size: .9rem;}
        .form-group{margin-bottom: .5rem;}
        .form-footer{
            input{
                width: 100%;
                text-align: center;
            }
        }
        
}

@include media-breakpoint-up(xl){
    #checkout{
       #wrapper{
        max-width: 70%;
        margin: 0 auto;
        // .checkout-row{justify-content: space-between;}
        #js-checkout-process{
            width: 75%;
            // background-color: lightyellow;
            // &:has(#delivery-addresses){
            //     width: 100%;
            // }
        }

        // #checkout-addresses-step{
        //     //max-width: 500px;
        // }

        // #delivery-address{
        //     background-color: blue;
        // }

        .order-checkout{
            width: 20%; // on réduit le résumé pour qu'il vienne sur le côté
            max-width: 350px;
        }
    
       } 
    }
}

#checkout{
    .delivery-option{
        display: flex;
        font-size: .9em;
        background-color: transparent;
        border-bottom: 1px solid $color19;
        padding: 1rem 0;
        // border: 1px solid black;
        .carrier-logo{
            display: none;
        }
        .small-price{
            color: $color1;
            font-weight: 500;
            margin-right: 2em;
        }

        label.delivery-option-2{
            span{
                display: block;
                text-align: center;
            }
            .name{
                // font-size: .9em;
                font-weight: 500;
            }
            .delay{font-style: italic;}
        }
        
    }

    #conditions-to-approve, .info{
        font-size: .8em;
    }
    #conditions-to-approve{margin: 1.5em 0;}
    .info{margin-top: 2em}
    #payment-confirmation{
        button{
            width: 100%;


        }
        .btn-primary:disabled{
            color: #a0a0a0;
            background-color: $disabled;
            border-color: $disabled;
        }
        .ps-shown-by-js{text-align: center;}
        span{display: block;}
        .secured{
           // color: $color-validate;
        }
        .mention{
            
            font-size: .9rem;
            font-style: italic;
            //text-decoration: underline;
        }
        
        a#btnPopover{
            width: 100%;
            padding: 0;
            padding-top: .6em;

            button{
                opacity: .90;
            }
        }
    }

}
@media (max-width: 350px){
    #checkout{
        .delivery-option{
            // background-color: #C201A5;
            .delivery-option-2{
                .delay{text-align: left;}
            }
        }
    }
}

// @include media-breakpoint-up(sm){
//     #checkout{
//         .delivery-option{
//             .carrier-logo{
//                 display: block;
//                 img{
//                     min-width: 100px;
//                     max-width: 150px;
//                 }
//             }
//         }
//     }
// }

//    #wrapper{
//             .checkout-process{
    
//                     .card{border: none;}
  
//             }

//     }


    // #wrapper{
    //         .checkout-process{
    //                 .step-title{
    //                     @extend .title-bloc;
    //                     padding-bottom: 1rem;          
    //                 }
    //                 .card{border: none;}
    //                 .card-header{
    //                     background-color: transparent;
    //                     border-bottom: none;
    //                 }
    //         }

    // }







//     #js-checkout-process{
//         // display: flex;
//         background: yellow;
//         width: 100%;

//     }

//     #content-checkout-personal-information-step{
//         display: flex;
//         width: 100%;
        
        

//             #cart-login{
                
//                 // width: 100%;
//                 // display: flex;

//             .cart-login-bloc{
//                 margin-bottom: 1.5rem;
//                 border: 1px solid $color1;

//                     h2{font-weight: 600;border-bottom: 1px solid $color1;}

//                     .cart-login-title2{
//                         display: block;
//                         font-size: .8em;
//                         font-style: italic;
//                         font-weight: 400;
//                     }
//                 }
//             }
//     }

// }

// // @media (max-width: 280px){
// //     #checkout {
// //         .step-title{
// //             font-size: .9rem;
// //             padding: 0;
// //         }
// //     }
// // }

// @include media-breakpoint-up(lg){
//     #checkout {
//         .checkout-process{
//             display: flex;
//             width: 100%;
//             border: 1px solid;


//                  #cart-login{
//                    // width: 70%;
//                    // display: flex;
//                   //  margin: 0 auto;
//                     background-color: lightgray;

//                  }
//         }

//             // résumé de la commande
//             .order-checkout{
//                 display: none;
//                 //display: block;
//                 // width: 20%;
//                 background-color: yellow;
//             }

//     }   
// }