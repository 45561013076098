// Breakpoint entre XS et SM @media (max-width: 320px)

// body{
//    background-color: $wrapper-bg;
// }

//
// Links 
// Redéfinition des couleurs de survol
//
a {
  color: $link-color;
  text-decoration: none;

  @include hover() {
    color: $color1;
    //text-decoration: $link-hover-decoration;
  }
}

// titres violet
.title{
  font-family: 'Quicksand', sans-serif;
  //color: #c201a5;
  font-weight: 600;
  padding-bottom: .5rem;
  border-bottom: 1px solid $color0;
  background-color: transparent;
  
}

.title-bloc{
    color: #c201a5;
    font-family: 'Quicksand', sans-serif;
    // text-transform: capitalize;
    text-transform: none;
    border-bottom: 1px solid #c201a5;
    background-color: transparent;
}

// a{
//   text-decoration: none;
// }

// Boutons
.btn-secondary{
  background-color: $color0;
  color: $white;
  border-color: $color0;
  
  // &:hover{
  //   background-color: $color1;
  //   border-color: $color1;
  // }
}

.info-important{
  font-weight: 600;
  color: $color-twitter;
}

.additional{
  font-style: italic;
  font-size: .8rem;
}


sup{
  top:-0.3em;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}

// #cms{
  .page-wrapper{
    border: none;
        .page-header{
          @extend .title-bloc;
          
          h1{
            text-transform: capitalize!important;
          }
        }
  }

// }


.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  box-shadow: $bs-touchspin-boxshadow;
  border:$input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}


// accordion (product page)
.accordion_title{
  color:$black;

  &:visited,&:hover{
    color: $black;
  }
  
  // text-transform: uppercase;
   display: block;
  // font-weight: 700;
   margin-bottom: $spacer/2;

  &:after{
    content:"\e313";
    font-family: 'Material Icons';
    font-feature-settings: 'liga' 1;
    position: absolute;
    right: $grid-gutter-width/2;
    transition: .3s transform ease-in-out;
    font-size: $font-size-base *1.5;
  }
  &[data-toggle="collapse"][aria-expanded="true"]:after {
    transform:         rotate(180deg);
  }
}


// authentication

// page.tpl - bloc vous n'avez pas encore de compte
#subscribe-bloc,
.register-form p{
 // margin: 3em;
  text-align: center;
  .btn{
    margin-left: 1.5em;
  } 
}
#subscribe-bloc{
  // border: 1px solid red;
  margin: 3em;
  // text-align: center;
  // .btn{
  //   margin-left: 1.5em;
  // } 
}

// authentication
#authentication{
    .page-header{
          h1{
            text-align: center;
            padding: 1rem 0;
          }
    }
    // .page-content{
    //   border-bottom: 1px solid $color1;
    // }

    .page-content--authentication{
      // border: 1px solid $color19;
      // background-color: $color17;
      margin: 1em 1.5em auto;
    }

    .form-footer{
      a{
        font-size: 1rem;
        
      }
    
    }
    .customer-account-info, .customer-account-info a{font-size: .9rem;}
    .customer-account-info{
      text-align: left;
      font-size: .9rem;
      font-style: italic;
      margin-top: 1.5rem;

        a{color:unset; text-decoration: underline;}

    }


}

@include media-breakpoint-up(xl){
  #authentication{
    .page-content--authentication{
    //  background-color:lightcoral;
      width: 50%;
      margin: 2.5em auto;
    }
  }
}

@media (min-width: 321px){
  #authentication{
      .form-footer{
        align-items: center;
        text-align: center;
        .no-account{
          margin: 1rem 0;
          a{
            text-decoration: underline;
          }
        }
      }
  }

}

// contact page
// #contact{
//       h1{
//         @extend .title-bloc;
//       }
//       #main{
//         border-color: $color1;
//       }

// }

// accueil espacement des blocs
.home-bloc{
  margin: 3em auto;
}

// titre des blocs (page  d'accueil)
h3.block-title{
  //color: #c201a5;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1.8rem;
  text-align: center;
  font-family: 'Quicksand', sans-serif;

      .title-decoration{
        position: absolute;
        height: .2rem;
        width: 90%;
        // background: #c201a5;
       background-color: #000;
      }
      .title-content{
        position: relative;
        top: -1rem;
        //margin-left: 2rem;
        margin: 0 auto;
        padding: 0.3rem 0.8rem;
        background-color: #FFF;
      }
}
@media (max-width: 320px){
  h3.block-title{
    font-size: 1rem;
    //padding: 0;
    text-align: center;
    // background-color: #c201a5;
    .title-content{
      top: -.5rem;
    }
  }
}