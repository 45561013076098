.social-sharing--product{
    // background-color: red;
    a{
        &:hover{background-color: #c201a5;}
    }
    .pinterest{
    
        &:hover{
           background-image: url(../img/pinterest-white.svg);
        }
    }
    .facebook{
    
        &:hover{
           background-image: url(../img/facebook-white.svg);
        }
    }
    .twitter{
    
        &:hover{
           background-image: url(../img/twitter-white.svg);
        }
    }
}