.featured-products{
        .products{

            .slick-list{
               display: grid;   // permet aux blocs d'avoir la hauteur maximale
            }
        }

    }

    #product main{
        .featured-products{
            .product-flags{
                .new{
                    position: relative;
                    transform: none;
                    padding: 0 0.5rem;
                }
            }
        }
    }